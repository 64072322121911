const advisory_list = [
  {
    index: 0,
    name: "Dr. Prachet Bhuyan",
    src: require("../assets/images/Ac1.jpg"),
    delay: 0.35,
    deperment: "School of Computer Science & Engineering"
  },
  {
    index: 2,
    name: "Dr. Priyadarshi Biswal",
    src: require("../assets/images/Ac3.JPG"),
    delay: 0.45,
    deperment: "Career Advisory & Augmentation Services (CAAS)"
  },
  {
    index: 1,
    name: "Dr. Anish Pandey",
    src: require("../assets/images/Ac2.jpg"),
    delay: 0.55,
    deperment: "School of Mechanical Engineering"
  },

  {
    index: 3,
    name: "Lipsa Subhadarshini",
    src: require("../assets/images/Ac4.jpg"),
    delay: 0.65,
    deperment: "School of Electrical Engineering"
  },
  {
    index: 4,
    name: "Debasmita Pradhan",
    src: require("../assets/images/Ac5.jpg"),
    delay: 0.70,
    deperment: "School of Computer Applications"
  },
  {
    index: 5,
    name: "Bindu Agarwalla",
    src: require("../assets/images/Ac6.jpg"),
    delay: 0.75,
    deperment: "School of Computer Engineering"
  },
];

export default advisory_list;
