import React from 'react'
import Footer from '../footer'
import Banner from './Banner'
import Opportunities from './Opportunities';
import Speaker from './Speaker';
import Faq from './Faq';
import AboutBot from './AboutBot';
import NavBarArea from './NavBarArea';

export default function HackABot() {
    return (
        <section style={{ background: "#010E12" }}>
            <NavBarArea />
            <Banner />
            <AboutBot />
            <Opportunities />
            <Speaker />



            <Faq />
            <Footer />
        </section>
    )
}
