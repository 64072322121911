import React from "react";
import Button from "../components/UiPath/Button";
import HackABotBtn from "../utils/UI/HackABotBtn";
import { Link } from "react-router-dom";
export default function Hackbot() {
  return (
    <div className="Hack_a_bot_event w-full">
      <div className="wrapper w-10/12 mx-auto py-4 my-4 rounded-lg border px-4 flex items-center flex-wrap">
        <div className="text md:w-9/12 w-full">
          <div className="title text-2xl">Hack A Bot</div>
          <div className="sub_title">
            We are excited to announce HACK-A-BOT, an inter-college bot-making
            competition organized by USC KIIT under the Department of Training
            and Placement.
          </div>
        </div>
        <div className="button md:w-3/12 w-full md:flex md:justify-center">
          <Link to={"/hack-a-bot"}>
            <HackABotBtn />
          </Link>
        </div>
      </div>
    </div>
  );
}
