import React from 'react'

export default function Card({ icon, title, text }) {
    return (
        <div className='w-full mt-7 flex justify-center items-center flex-col'>
            <div style={{ background: "#D9D9D9" }} className="iconBox rounded-full flex justify-center items-center text-3xl w-[60px] text-[#FE5A26] h-[60px]">
                {icon}
            </div>
            <div className="title mt-2 text-center">
                {title}
            </div>
            <div className="text text-center">
                {text}
            </div>
        </div>
    )
}
