import React from "react";
import img from "../../assets/HackbotImages/about.png";
import { CiCalendarDate } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { MdAccessTime } from "react-icons/md";
const extraData = [
  {
    id: 1,
    title: "Date",
    text: "1st September, 2024",
    icon: <CiCalendarDate />,
  },
  {
    id: 2,
    title: "Location:",
    text: "Campus-14, 3rd Floor",
    icon: <IoLocationOutline />,
  },
  {
    id: 3,
    title: "Time",
    text: "9 a.m. - 2 p.m.",
    icon: <MdAccessTime />,
  },
];
export default function AboutBot() {
  return (
    <div className="w-full mt-10 py-9">
      <div className="wrapper w-10/12 mx-auto flex flex-wrap items-center">
        <div className="md:w-4/12 w-full">
          <img src={img} alt="" />
        </div>
        <div className="md:w-8/12 w-full">
          <div className="heading">
            <div className="main_title font-extrabold text-4xl text-[#FE5A26]">
              ABOUT HACKABOT
            </div>
            <i>
              {/* <p className="my-2">
                Embrace the Prime Mentality: Can Your Autobot Forge Its Reality?
              </p> */}
            </i>
          </div>
          <div className="text my-6">
            Hack-a-bot is an exciting one-day event scheduled for 1st September,
            2024, focusing on the theme "Al with Automation." Participants will
            delve into the world of Al-driven automation, collaborating in teams
            of 1-4 students to create innovative solutions. There will be a
            two-day bot-making sessions on August 23th and 24th.
          </div>
          <div className="subData">
            {extraData.map((item, i) => {
              return (
                <li className="flex gap-2" key={i}>
                  <div className="icon text-xl">{item.icon}</div>
                  <div className="title">{item.title}</div>:
                  <div className="title">{item.text}</div>
                </li>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
