import React, { useState } from "react";

const Accordion = () => {
  const [openAccordion, setOpenAccordion] = useState(0);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const qnaList = [
    {
      id: 1,
      title: "Can I attend Hack-a-Bot if I'm new to Automation or Al?",
      text: "Yes, Hack-a-Bot events welcome participants of all skill levels. Upon satisfying the eligibility criteria, the participants will have the necessary skills for Hack-a- bot",
    },
    {
      id: 2,
      title:
        "How can participating in a Hack-a-Bot benefit my education or career?",
      text: "Hack-a-Bot events provide opportunities to gain hands-on experience, network with peers and industry professionals, showcase your skills and creativity, and potentially win awards or recognition that can enhance your skillset and resume.",
    },
    {
      id: 3,
      title: "What is the maximum team size allowed in the Hack-a-bot?",
      text: "The maximum team size allowed is 4 students.",
    },
    {
      id: 4,
      title: "Can a student be part of more than one team?",
      text: "No, a student can be part of only one team.",
    },
    {
      id: 5,
      title: "Will all the participants get any certificate?",
      text: "Yes, all the eligible participants will receive a certificate of participation.",
    },
    {
      id: 6,
      title: "Will the prize money be awarded on the day of Hack-a-bot?",
      text: "No. Prize Money will be transferred directly to the winners post project verification by the Mentor followed by eligibility verification by the UiPath team.",
    },
    {
      id: 7,
      title:
        "What are the eligibility criteria for the students to participate in Hack-a-bot?",
      text: "All participants should have completed the Automation Explorer Leaming plan on UiPath Academy before the hackathon.",
    },
  ];

  return (
    <div id="accordion-open">
      {qnaList.map((item, i) => (
        <div key={item.id} className="my-2 py-2">
          <h2 id="accordion-open-heading-1">
            <button
              style={{
                height: "auto",
              }}
              type="button"
              className="flex items-center justify-between w-full p-5 py-2 font-medium rtl:text-right text-gray-500  border-b-0 border-gray-200 rounded-t-xl   dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
              onClick={() => toggleAccordion(item.id)}
              aria-expanded={openAccordion === 1}
              aria-controls="accordion-open-body-1"
            >
              <div className="flex items-center gap-4">
                <div className="id">
                  {item?.id >= 1 && item?.id <= 9 ? "0" + item?.id : item?.id}
                </div>

                <div className="title text-left py-2">{item.title}</div>
              </div>
              <svg
                className={`w-3 h-3 shrink-0 transition-transform duration-200 ${
                  openAccordion === item.id ? "rotate-180" : ""
                }`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5 5 1 1 5"
                />
              </svg>
            </button>
          </h2>
          <div
            id="accordion-open-body-1"
            className={`${openAccordion === item.id ? "" : "hidden"}`}
            aria-labelledby="accordion-open-heading-1"
          >
            <div className="p-5  border-b-0  dark:border-gray-700 dark:bg-gray-900">
              <p className="mb-2 text-gray-500 dark:text-gray-400">
                {item.text}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
