import React from "react";
import speaker from "../../assets/HackbotImages/speaker.png";
export default function Speaker() {
  return (
    <div className="w-full">
      <div className="wrapper w-10/12 mx-auto">
        <div className="top_area flex flex-wrap gap-3 my-7">
          <div className="w-3/12">
            <img
              src={speaker}
              className="w-full rounded-lg"
              alt="speaker_image"
            />
          </div>
          <div className="w-7/12">
            <div className="spearDescrbtion">
              <div className="name font-bold text-4xl">Vishwa Mohan</div>
              <div className="currentJob text-[#FF5F2C] mb-6">
                Founder and CEO -AIMERZ.ai
              </div>
              <div className="past_role">
                <b>Ex CIO</b>- Physics Wallah <br /> <b>Ex SDE</b>- Paypal,
                Walmart, Linkendin, Amazon, Oracle
              </div>
            </div>
          </div>
        </div>
        <div className="about_speaker w-10/12">
        We are thrilled to announce that Mr. Vishwa Mohan, the visionary CEO of AIMERZ, will be our esteemed guest speaker at Hack-a-Bot, our intercollege competition. Mr. Mohan, a renowned figure in the tech industry, will deliver an invaluable speech on "How to Be in the Top 1% of Techies in the World." His insights, drawn from years of experience and leadership in cutting-edge technology, will inspire and empower students as they navigate their journey to becoming top-tier professionals. This session promises to be a highlight of Hack-a-Bot, motivating participants to push boundaries and achieve excellence in their tech careers.
        </div>
      </div>
    </div>
  );
}
