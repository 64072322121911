const MentorData = [
  {
    index: 1,
    name: 'Dr. Abhishek Ray',
    src: require('../assets/images/facultyImages/abhishek_2.jpg'),
    role: "Touchstone Nexus",

    position:
      "Professor & Dean (Industry Engagements),School of Computer Engineering,KIIT Deemed to be University",
    animation_delay: .25

  },
  {
    index: 0,
    name: 'Dr. Subhra Debdas',
    src: require('../assets/images/facultyImages/Subhra.jpg'),
    role: "Former Curriculum Coordinator",

    position:
      "Associate Professor and Associate Dean University PG Program,School of Electrical Engineering,KIIT Deemed to be University",
    animation_delay: .15
  },

]

export default MentorData;
