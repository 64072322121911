import React from "react";
import img from "../../assets/HackbotImages/c_logo.png";
import img2 from "../../assets/HackbotImages/com_logo.png";
export default function NavBarArea() {
  return (
    <nav className="flex justify-between  px-6 py-6">
      <div className="img">
        <img className="w-[300px]" src={img} alt="" />
      </div>
      <div className="img">
        <img className="w-[100px]" src={img2} alt="" />
      </div>
    </nav>
  );
}
