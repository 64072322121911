const FormerLeadData = [


  {
    index: 6,
    src: require("../assets/images/leads/Devangi.jpg"),
    name: "Devangi Das",
    postion: "Web Development",
    instagram:
      "https://www.instagram.com/exquisite_artemis?igsh=MWtudXlqbmltYjcwOQ==",
    gamil: "devangiofficial@gmail.com",
    linkedin: "https://www.linkedin.com/in/devangi-das-667935209/",
  },



  {
    index: 11,
    src: require("../assets/images/leads/komal.png"),
    name: "Komal Jaiswal",
    postion: "Social Media Marketing",
    instagram:
      "https://www.instagram.com/komaljaiswal237?igsh=MTlpNjdwMHk2bHpheg==",
    gamil: " ",
    linkedin: "https://www.linkedin.com/in/komal-jaiswal-9347522a6/",
  },

  {
    index: 6,
    src: require("../assets/images/leads/mayank ph.jpg"),
    name: "Mayank Singhal",
    postion: "Core ML",
    instagram:
      "https://www.instagram.com/exquisite_artemis?igsh=MWtudXlqbmltYjcwOQ==",
    gamil: "devangiofficial@gmail.com",
    linkedin: "https://www.linkedin.com/in/devangi-das-667935209/",
  },



  {
    index: 11,
    src: require("../assets/images/leads/yatish.jpg"),
    name: "Yatish Raj",
    postion: "Graphic Design",
    instagram:
      "https://www.instagram.com/komaljaiswal237?igsh=MTlpNjdwMHk2bHpheg==",
    gamil: " ",
    linkedin: "https://www.linkedin.com/in/komal-jaiswal-9347522a6/",
  },






];

export default FormerLeadData;
