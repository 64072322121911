import React from "react";
import Card from "./card";
import { LuMessagesSquare } from "react-icons/lu";
import { FiUsers } from "react-icons/fi";
import { GiPublicSpeaker } from "react-icons/gi";
import { PiCertificateBold } from "react-icons/pi";
import { GiTrophyCup } from "react-icons/gi";
const LeftData = [
  {
    id: 1,
    data: "Connect with people, form a team, learn new skills and develop amazing projects!",
    title: "Collaborate and skill up",
    icon: <LuMessagesSquare />,
  },
  {
    id: 2,
    data: "Exciting Goodies for Participants throughout the Event!",
    title: "Goodies for Participants",
    icon: <FiUsers />,
  },
];
const centerData = [
  {
    id: 1,
    title: "Prizepool : 10k+ Awards and more",
    data: "Exciting Prizes for Winners and Dedicated Trophies for all wining Teams!",
    icon: <LuMessagesSquare />,
  },
];
const rightData = [
  {
    id: 1,
    title: "Speaker Session and Quizes",
    data: "Speaker Session and Quizes to keep participants engaged throughout the Event!",
    icon: <GiPublicSpeaker />,
  },
  {
    id: 2,
    title: "Certificates for all Participants",
    data: "Every HackAbot Participant will receive a Certificate from our side!",
    icon: <GiTrophyCup   />,
  },
];
export default function Opportunities() {
  return (
    <div className="w-full py-6 my-5">
      <div className="heading text-center">
        <p className="text-[12px]">Opportunities for Participants</p>
        <div className="title text-4xl my-2">Why participate in Hackabot?</div>
        <p>HackAbot promises a wonderful experience to the participants.</p>
      </div>
      <div
        style={{ background: "rgb(217 217 217 / 19%)" }}
        className="wrapper w-10/12 py-4 px-8 my-5 rounded-lg mx-auto  flex flex-wrap gap-2 items-center justify-center"
      >
        <div className="lg:w-3/12 md:w-6/12 w-full">
          {LeftData.map((item, i) => (
            <Card
              key={i}
              icon={item.icon}
              title={item.title}
              text={item.data}
            />
          ))}
        </div>
        <div className="lg:w-4/12 md:w-6/12 w-full">
          {centerData.map((item, i) => (
            <Card
              key={i}
              icon={item.icon}
              title={item.title}
              text={item.data}
            />
          ))}
        </div>
        <div className="lg:w-3/12 md:w-6/12 w-full">
          {rightData.map((item, i) => (
            <Card
              key={i}
              icon={item.icon}
              title={item.title}
              text={item.data}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
