import React from "react";
import img from "../../assets/HackbotImages/hackabot.png";
import trophy from "../../assets/HackbotImages/trophy-dynamic-premium.png";

import Button from "./Button";

export default function Banner() {
  return (
    <div className="w-full">
      <div className="wrapper w-10/12 mx-auto flex justify-center items-center flex-col">
        <div className="hackBotImg">
          <img src={img} alt="" />
        </div>
        <div className="border border-slate-600 p-4 my-3 text-center text-lg">
          <b>
            {" "}
            We are excited to announce HACK-A-BOT, an inter-college bot-making
            competition organized by USC KIIT under the Department of
            Training and Placement.
          </b>
        </div>
        <center>
          <a target="_blank" href="https://forms.gle/rNQfJTbmXzXXJLjF7">
            <button
              style={{
                borderWidth: "3px",
                borderStyle: "solid",
                borderColor: "#AE8ADC",
              }}
              className=" border border-[#AE8ADC] bg-[#090909] rounded-full text-white w-[250px] py-4  mt-4"
            >
              REGISTER NOW
            </button>
          </a>
          <div className="main_prize_wrapper relative w-[400px] my-9">
            <div className="bg_img absolute top-0 right-[46px]">
              <img src={trophy} className="w-[80px]" alt="tropy" />
            </div>
            <div
              className="prise_monney relative w-[350px] text-6xl font-semibold my-3"
              style={{ lineHeight: "1", zIndex: "10" }}
            >
              <span style={{ color: "#E23E0B", fontStyle: "italic" }}>
                10k+
              </span>{" "}
              <br /> Prizepool
            </div>
          </div>
        </center>
      </div>
    </div>
  );
}
